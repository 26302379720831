.header {
    font-family: 'PT Sans';
    font-weight: bold;
}

.header_highlighted {
    font-family: 'PT Sans';
    font-weight: bold;
    background-color: pink;
}

.headerContainer {
    background-color: aqua;
}
.ht_master .wtHolder{
    overflow: auto;
    background: white;
}


.css-ur97uj,.css-1hx7vxy{
    z-index: 0 ;
}