.tableWraper td {
  border: none !important;
}

#fixedTableHeader thead {
  background-color: #fff !important;
  position: sticky;
  top: 0;
  z-index: 1;
}
