.chakra-modal__overlay {
   opacity: 0.8 !important;
}

#pdf-zoom-in{
   display: none;
}

#pdf-zoom-out {
   display: none;
}

#pdf-controls{
   box-shadow: none;
}