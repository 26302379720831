.rdrNextPrevButton {
  border-radius: 16px;
  background-color: transparent;
  border: 1px solid grey;
}

.rdrMonthPicker select option {
  background: #fff;
  color: #43dad4;
}

.datepicker .rdrMonthAndYearPickers select {
  color: #43dad4;
  font-weight: 900;
  font-size: 18px;
}

.datepicker .rdrMonthAndYearPickers select::-webkit-scrollbar {
  display: none;
}

.datepicker1 .rdrMonthAndYearPickers select::-webkit-scrollbar {
  display: none;
}

.datepicker1 .rdrMonthAndYearPickers select {
  color: #023b93;
  font-weight: 900;
  font-size: 18px;
}

.datepicker1 select option {
  background: #fff;
  color: #023b93;
}
