body {
  margin: 0;
}

.orc_table Table {
  width: 100%;
}

.orc_table Th {
  font-size: 10px !important;
  padding: 5px !important;
  color: black !important;
}

.orc_table Td {
  font-size: 12px !important;
  padding: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 500;
}

.orc_table Tbody Td{
vertical-align: baseline !important;
}

.orc_table Thead Th,
.orc_table Tfoot Tr {
  position: sticky;
  background-color: #fff;
}

.orc_table Tbody Tr {
  border-bottom: 1px solid gray;
}

.orc_table Thead Th {
  top: 0;
}

.orc_table Tfoot Tr {
  bottom: 0;
}

.orc_table Th,
.orc_table Td {
  font-size: 12px;
  text-align: center;
}

/*give some space between thead and tfoot*/

.orc_table Tbody Tr:first-of-type td {
  padding-top: 10px;
}

.orc_table Tbody Tr:last-of-type td {
  padding-bottom: 10px;
}
