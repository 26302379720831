* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}
